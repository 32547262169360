<template>
  <div class="login-container">
    <vue-particles
        color="#AEDFFE"
        :particleOpacity="0.7"
        :particlesNumber="80"
        shapeType="circle"
        :particleSize="4"
        linesColor="#dedede"
        :linesWidth="1"
        :lineLinked="true"
        :lineOpacity="0.4"
        :linesDistance="150"
        :moveSpeed="3"
        :hoverEffect="true"
        hoverMode="grab"
        :clickEffect="true"
        clickMode="push"
        style="height: 100%;"
    />
    <div style="position: absolute;top:4rem;left: 6rem;">
      <div class="flex flex-ac">
        <div style="font-size: 42px;font-weight: bolder;color: #fff;">Redid</div>
        <div style="font-size: 32px;font-weight: bolder;color: #fff;margin-left: 0.2rem;">开放平台</div>
      </div>
      <div style="font-size: 24px;font-weight: bolder;color: #fff;">
        __新时代应用开拓者_（敬请期待，预计2025年3月发布正式版本）
      </div>
    </div>
    <!-- 登录表单 -->
    <el-form :model="ruleForm" :rules="rules" status-icon ref="ruleForm" label-position="left" label-width="0px" class="login-page">
      <div class="title">登录redid开放平台</div>
      <el-form-item prop="username">
        <el-input type="text" v-model="ruleForm.username" placeholder="用户名 / 邮箱 / 电话号码" auto-complete="off"
                  prefix-icon="el-icon-user"></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input show-password v-model="ruleForm.password" placeholder="密码" auto-complete="off"
                  prefix-icon="el-icon-lock"></el-input>
      </el-form-item>
      <div style="margin-bottom: 20px;" class="flex flex-jsb">
        <el-checkbox size="medium" v-model="checked" class="stayLoggedIn">保持登录</el-checkbox>
        <el-link :underline="false" class="forget_pw" @click="resetPassword">忘记密码？</el-link>
      </div>
      <el-form-item style="width:100%;">
        <el-button type="primary" id="loginButton" style="width:100%;" @click="handleSubmit" :loading="loading">立即登录</el-button>
      </el-form-item>
      <router-link class="flex-ajc register" to="/register">没有redid账户？立即注册</router-link>
    </el-form>
    <!-- 在 </div> 关闭标签之前添加版权信息 -->
    <div class="copyright flex-ajc">
      © 2024 Redid Project. All rights reserved.
      <div style="margin-left: 12px;">|</div>
      <el-link target="_blank" style="margin-left: 12px;color: #FFFFFF;font-size: 14px;" :underline="false" href="https://beian.miit.gov.cn/">备案号：蜀ICP备19030913号-1</el-link>
    </div>
  </div>
</template>

<script>
import router from "@/router"
require('@/assets/geetest4')
export default {
  data() {
    return {
      // 按钮loading状态
      loading: false,
      // 表单数据
      ruleForm: {},
      // 表单校验规则
      rules: {
        username: [{
          required: true,
          message: '请输入用户名 / 邮箱 / 电话号码',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }]
      },
      // 是否选择记住密码
      checked: false
    }
  },
  created() {
    console.log("亲爱的小伙伴们，你们好呀，这是一个彩蛋，只有点进这个页面的小伙伴才能看到，很高兴认识你们，祝你们每天都开心愉快，充满好运😊🎉🌙")
  },
  mounted() {
    this.initGeeTest();
  },
  methods: {
    resetPassword(){
      router.push('/resetPassword')
    },
    initGeeTest() {
      let _this = this;
      window.initGeetest4({
        captchaId: '3852403296691657cab0591c806db96c',
        product: 'bind',
        protocol: "https://"
      }, function (captcha) {
        // captcha为验证码实例
        window.captcha = captcha;
        captcha.onReady(function () {
          //your code
        }).onSuccess(function () {
          let result = window.captcha.getValidate();
          let data = _this.ruleForm;
          data['lotNumber'] = result.lot_number;
          data['captchaOutput'] = result.captcha_output;
          data['passToken'] = result.pass_token;
          data['genTime'] = result.gen_time;
          data['remembered'] = _this.checked;
          _this.$store.dispatch('user/login', data).then(()=> {
            // 跳转到主页面（overview）
            router.push('/').then(()=> {})
            _this.loading = false;
          }).catch(()=> {
            _this.loading = false;
          })
        }).onError(function () {
          //your code
          _this.loading = false;
        }).onClose(function () {
          // 用户把验证关闭了，这时你可以提示用户需要把验证通过后才能进行后续流程
          _this.loading = false;
        });
      });
    },
    // 处理登录
    handleSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // 校验通过，请求登录
          window.captcha.showCaptcha();
          this.loading = true;
        } else {
          // 校验未通过
          this.$message.error("登录校验未通过，请核对！")
        }
      })
    }
  }
};
</script>

<style lang="less" scoped>
.login-container {
  width: 100%;
  height: 100%;
  background-image: url('~@/assets/overview_background.webp'); /* 背景图 */
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.login-page {
  position: absolute;
  top: 28%; /* 调整垂直位置 */
  left: 60%; /* 调整水平位置 */
  -webkit-border-radius: 10px;
  border-radius: 10px;
  width: 286px; /* 根据需要调整宽度 */
  padding: 35px 45px 20px;
  background: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px #cac6c6;
}

.title {
  text-align: center;
  font-weight: bolder;
  font-size: 17px;
  margin-bottom: 0.5rem;
  margin-top: 0.2rem;
  opacity: 0.88;
}

.copyright {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  text-align: center;
  color: #fff; /* 确保颜色与背景图对比明显 */
  font-size: 14px;
}

.stayLoggedIn {
  font-size: 12px;
  color: #b6babb;
}

.forget_pw {
  color: #b6babb;
}

#loginButton {
  font-weight: bolder;
}

.register {
  color: #3a8ee6;
  margin-bottom: 20px;
  font-weight:bold;
  opacity: 0.6;
}
</style>
